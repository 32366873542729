import { Lang, PlaceDetails, Translations } from '@sbiz/common';
import { Json, Types, WithId } from '@sbiz/util-mongodb';

export const API_EXCEPTIONS = {
  BENEFITS_CODE_ALREADY_IN_USE: 'Code already used in another voucher',
  BENEFITS_DISABLED: 'Benefits have been deactivated for the company',
  BENEFITS_NO_VALID_PROFILE: 'No valid profile was provided at the time of benefit creation',
  BENEFITS_NOT_FOUND: 'Missing benefit',
  BENEFITS_UNAUTHORIZED_COMPANY: 'The company in not authorized to use benefits',
  COMPANIES_COST_CENTERS_EMPTY_FILE: 'Could not parse the csv file',
  COMPANIES_UID_ALREADY_IN_USE: 'A company with this uid is already registered',
  COMPANIES_UID_INVALID: 'Invalid company unique identifier',
  CRON_TOKEN_INVALID: 'Invalid cron token',
  CRON_TOKEN_MISSING: 'Missing cron token',
  CRON_TOKEN_MISSING_CONFIG: 'Missing cron token configuration',
  CUSTOMERS_NOT_FOUND_COMPANY: 'Missing company',
  CUSTOMERS_NOT_FOUND_EMPLOYEE: 'Missing employee',
  CUSTOMERS_NOT_FOUND_PROFILE: 'Missing profile',
  DATATRANS_CANCELATION_CONFLICT: 'The Datatrans transaction cannot be canceled',
  DATATRANS_SETTLEMENT_CONFLICT: 'The Datatrans transaction cannot be settled',
  EMPLOYEE_INVITATIONS_ALREADY_ENROLLED: 'This invitation has already been accepted',
  EMPLOYEE_INVITATIONS_CUSTOMER_ID_ALREADY_IN_USE: 'Customer id already attached to another employee',
  EMPLOYEE_INVITATIONS_EMPTY_FILE: 'Could not extract rows from the csv file',
  EMPLOYEE_INVITATIONS_NOT_FOUND: 'Missing employee invitation',
  EMPLOYEE_INVITATIONS_NOT_FOUND_COMPANY: 'Missing company',
  EMPLOYEE_INVITATIONS_NOT_FOUND_CUSTOMER: 'Missing customer',
  EMPLOYEE_INVITATIONS_NOT_FOUND_PROFILE: 'Missing profile',
  EMPLOYEE_INVITATIONS_TOO_EARLY: 'Too early to resend the invitation',
  EMPLOYEE_INVITATIONS_UNKNOWN: undefined,
  EMPLOYEES_NOT_FOUND: 'Missing employee',
  EMPLOYEES_NOT_FOUND_CUSTOMER: 'Missing customer',
  EMPLOYEES_NOT_FOUND_PROFILE: 'Missing profile',
  JWT_EXPIRED: 'Expired JSON Web token',
  JWT_INVALID: 'Invalid JSON Web Token',
  KEYCLOAK_MISSING_PERMISSION: 'Missing permission',
  KEYCLOAK_MISSING_PUBLIC_KEY: 'Missing public key',
  KEYCLOAK_MISSING_TOKEN: 'Missing token',
  LOGIN_INVALID_CREDENTIALS: 'Invalid email or password',
  MANAGER_INVITATIONS_ALREADY_ACCEPTED: 'This invitation has already been accepted',
  MANAGER_INVITATIONS_NOT_FOUND: 'Missing manager invitation',
  MANAGER_INVITATIONS_NOT_FOUND_COMPANY: 'Missing company',
  MANAGER_INVITATIONS_TOO_EARLY: 'Too early to resend the invitation',
  MANAGERS_EMAIL_ALREADY_IN_USE: 'This email address is already in use',
  ORDERS_NOT_FOUND_COST_CENTER: 'Missing cost center',
  PERMISSIONS_FORBIDDEN_SELF_EDIT: 'Managers are not allowed to edit their own permissions',
  PROFILES_MISSING_DEFAULT: 'The default profile is not set for this company',
  PROFILES_NAME_ALREADY_IN_USE: 'Profile name already in use',
  STATEMENTS_INVALID_DATE_RANGE: 'Invalid date range',
  STATEMENTS_NOT_FOUND: 'Missing statement',
  STATEMENTS_NOT_FOUND_COMPANY: 'Missing company',
  VALIDATION_PIPE_INVALID: 'Validation failed',
} as const;
export type ApiExceptionCode = keyof typeof API_EXCEPTIONS;
export const API_EXCEPTIONS_CODES = Object.keys(API_EXCEPTIONS) as ApiExceptionCode[];

export const ADMIN_PERMISSIONS_NAME = 'SmoodAdmin';
export const DEFAULT_PERMISSIONS_NAME = 'PortalManager';

export type CompanyCostCenters = { filename?: string; filepath?: string; list: string[] };
export type CompanyFee = { isCharged: boolean; percentage?: number };
export type CompanyFees = { service?: CompanyFee };
export type CompanyFeesSetting = { service: { percentage: number } };
export type CompanyLocation = { address: PlaceDetails; name: string; fullAddress: string; radius: number };
export type CompanyRebate = { isActive: boolean; items?: CompanyRebateItem[] };
export type CompanyRebateItem = { threshold: number; value: { percentage: number } };
export type CompanySetting = { fees: CompanyFeesSetting; rebate: CompanyRebateItem[] };

export const STATEMENT_FORMATS = ['default', 'detailed'] as const;
export type StatementFormat = (typeof STATEMENT_FORMATS)[number];

export const STATEMENT_THEMES = ['bnw', 'color'] as const;
export type StatementTheme = (typeof STATEMENT_THEMES)[number];

export type Company = WithId<{
  benefitsActivated: boolean;
  companyRegister?: string;
  contactEmail: string;
  contactEmailFinance?: string[];
  contactPhoneNumber: string;
  costCenters?: CompanyCostCenters;
  createdAt: Date;
  deliveryAddress?: string;
  dropOffAreas?: CompanyLocation[];
  externalReference?: string;
  fees?: CompanyFees;
  invoiceAddress?: string;
  invoiceColorTheme?: StatementTheme;
  invoiceContactName?: string;
  invoiceFormat?: StatementFormat;
  lang?: Lang;
  name: string;
  paymentTerms: number;
  portalAdminContact?: Types.ObjectId;
  postalAddress: string;
  prefix?: string;
  purchaseOrderNumber?: string;
  rebate?: CompanyRebate;
  receivableLimit: number;
  salesRepresentativeEmail?: string;
  shouldEmailForNewInvoice?: boolean;
  updatedAt: Date;
  vatNumber?: string;
  website?: string;
}>;

export type CompanyJson = Json<Company>;

export const BENEFIT_REDUCTION_TYPES = ['fixedAmount', 'freeDelivery'] as const;
export type BenefitReductionType = (typeof BENEFIT_REDUCTION_TYPES)[number];

export type BenefitRestrictedZone = {
  formatted: string;
  location: { latitude: number; longitude: number };
  name: string;
  radius: number;
};

export type BenefitSchedule = { maxUse?: number; weekdays: string; from: string; to: string };

export type CheckDistributionEmployee = WithId<{
  amount: number;
  customerId: string;
  email: string;
  endDate?: Date;
  firstname: string;
  isSuccess: boolean;
  lastname: string;
  startDate?: Date;
}>;

export type Customer = { _id: string; email: string; b2b?: CustomerBusinessDetails };
export type CustomerBusinessDetails = { company: string; profile: string };

export const DURATION_UNITS = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'] as const;
export type DurationUnit = (typeof DURATION_UNITS)[number];

export type InvitationInterval = { amount: number; unit: DurationUnit };

export type OfferValue = { percentage: number };

export const ENDED_ORDER_STATUSES = ['complete', 'canceled'] as const;
export type EndedOrderStatus = (typeof ENDED_ORDER_STATUSES)[number];
export const ORDER_SUMMARY_STATUSES = [...ENDED_ORDER_STATUSES, 'pending'] as const;
export type OrderSummaryStatus = (typeof ORDER_SUMMARY_STATUSES)[number];

export type OrderBenefit = {
  amount: number;
  code: string;
  isFreeDelivery?: boolean;
};

export type OrderSummaryBenefit = OrderBenefit & { isFreeDelivery: boolean };

export type OrderSummary = {
  benefits: OrderSummaryBenefit[];
  companyId: string;
  number: string;
  status: OrderSummaryStatus;
};
export type EndedOrderSummary = OrderSummary & { status: EndedOrderStatus };

export type OrderAddress = {
  country: string;
  formatted: string;
  latitude: number;
  locality: string;
  longitude: number;
  note: string;
  postalCode: string;
  route: string;
  streetNumber: string;
};

export type OrderProductChoice = {
  name: Translations;
  quantity: number;
  refChoice: string;
};

export type OrderProduct = {
  id: string;
  categoryName?: Translations;
  combinationChoices?: OrderProductChoice[];
  description?: string;
  name: Translations;
  quantity: number;
  totalPrice: number;
};

export type OrderStore = {
  id: string;
  url: string;
};

export type Order = {
  _id: string;
  b2b: { profile: string; profileName: string };
  benefits: OrderBenefit[];
  canceledAt?: string;
  completedByRestaurantAt?: string;
  costCenter?: string;
  createdAt: string;
  customerName: string;
  deliveredAt?: string;
  dropoffAddress: OrderAddress;
  dropoffDeadline: string;
  fromAccount: number;
  fromCheckAccount: number;
  number: string;
  pickupAddress: OrderAddress;
  restaurantName: string;
  serviceFee: number;
  status: string;
  totalOrder: number;
  totalOrderWithTips: number;
  totalPaid: number;
  totalProducts: number;
  totalShipping: number;
  vouchersDetails: { code: string; id: string }[];
};

export const PERMISSIONS_ACTIONS = ['read', 'write'] as const;
export type PermissionsAction = (typeof PERMISSIONS_ACTIONS)[number];

export const PERMISSIONS_NAMES = [DEFAULT_PERMISSIONS_NAME, ADMIN_PERMISSIONS_NAME] as const;
export type PermissionsName = (typeof PERMISSIONS_NAMES)[number];

export const PERMISSIONS_SCOPES = [
  'accounting',
  'adminCompanies',
  'adminDatatrans',
  'adminEmployees',
  'adminGiftCardRequests',
  'adminOpenInvoices',
  'adminPermissions',
  'apiUrl',
  'benefits',
  'companyAllowableExpense',
  'companyCostCenter',
  'companyInformation',
  'companyInvoiceSetting',
  'companyLocation',
  'companySelection',
  'employees',
  'giftCards',
  'managers',
  'orders',
  'permissionsSet',
  'profiles',
  'purchases',
  'smoodChecks',
] as const;
export type PermissionsScope = (typeof PERMISSIONS_SCOPES)[number];

export type StatementCompany = Pick<
  Company,
  | '_id'
  | 'companyRegister'
  | 'contactEmailFinance'
  | 'externalReference'
  | 'fees'
  | 'invoiceAddress'
  | 'invoiceContactName'
  | 'name'
  | 'paymentTerms'
  | 'postalAddress'
  | 'purchaseOrderNumber'
  | 'rebate'
  | 'vatNumber'
>;

export type StatementCheckPurchaseDetails = WithId<{
  check: WithId<{ amount: number; dayOfDistribution: number; name: string }>;
  createdAt: Date;
  employees: ({ originalAmount: number } & CheckDistributionEmployee)[];
  profile: WithId<{ name: string }>;
}>;
export type StatementGiftCardPurchaseDetails = WithId<{
  amount: number;
  issuedAt?: Date;
  quantity: number;
  nameForClient: string;
}>;
export type StatementOrderPurchaseDetails = Pick<
  Order,
  | 'benefits'
  | 'completedByRestaurantAt'
  | 'costCenter'
  | 'deliveredAt'
  | 'dropoffAddress'
  | 'customerName'
  | 'restaurantName'
  | 'number'
>;
export type StatementPurchase<T> = {
  amount: number;
  details: T;
  paidAmount: number;
  remainder: number;
};
export type StatementPurchases<T> = {
  amount: number;
  items: StatementPurchase<T>[];
  paidAmount: number;
  remainder: number;
};
export type StatementRebate = {
  amount: number;
  isActive: boolean;
  items: ({ amount: number; eligibleAmount: number } & CompanyRebateItem)[];
  originalAmount: number;
};
export type StatementServiceFee = {
  amount: number;
  isCharged: boolean;
  offer?: OfferValue;
  originalAmount: number;
  percentage: number;
};
export type Statement = {
  company: StatementCompany;
  checkDistributions: StatementPurchases<StatementCheckPurchaseDetails>;
  dueAt: Date;
  giftCards: StatementPurchases<StatementGiftCardPurchaseDetails>;
  orders: StatementPurchases<StatementOrderPurchaseDetails>;
  paidAmount: number;
  rebate: StatementRebate;
  remainder: number;
  serviceFee: StatementServiceFee;
  setting: CompanySetting;
  subTotal: number;
  total: number;
};
