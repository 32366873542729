import { ReactNode } from 'react';

import { PermissionsScope } from '@sbiz/business';
import { LangParams, Value, ValueProperty } from '@sbiz/common';

export type MetadataGeneratorProps = { searchParams: Record<string, string> } & PageProps;

export type PageProps = { params: LangParams };

export const ACCOUNTING_PAGE_NAME = 'accounting';
export const ADMIN_PAGE_NAME = 'admin';
export const BENEFITS_PAGE_NAME = 'benefits';
export const CHECKS_PAGE_NAME = 'smoodChecks';
export const COMPANY_PAGE_NAME = 'company';
export const COMPANY_SELECTION_PAGE_NAME = 'companySelection';
export const EMPLOYEES_PAGE_NAME = 'employees';
export const FORGOT_PASSWORD_PAGE_NAME = 'forgotPassword';
export const GIFT_CARDS_PAGE_NAME = 'giftCards';
export const HOME_PAGE_NAME = 'home';
export const LOGIN_PAGE_NAME = 'login';
export const LOGIN_SUCCESS_PAGE_NAME = 'loginSuccess';
export const LOGOUT_PAGE_NAME = 'logout';
export const LOGOUT_SUCCESS_PAGE_NAME = 'logoutSuccess';
export const MANAGERS_PAGE_NAME = 'managers';
export const ONBOARDING_PAGE_NAME = 'managerOnboarding';
export const ORDERS_PAGE_NAME = 'orders';
export const PASSWORD_RESET_PAGE_NAME = 'passwordReset';
export const PERMISSIONS_SETS_PAGE_NAME = 'permissionsSets';
export const PROFILE_PAGE_NAME = 'profile';
export const PROFILES_PAGE_NAME = 'profiles';
export const PURCHASES_PAGE_NAME = 'purchases';
export const REGISTER_PAGE_NAME = 'register';
export const SETTINGS_PAGE_NAME = 'settings';

export type PageName =
  | typeof ACCOUNTING_PAGE_NAME
  | typeof ADMIN_PAGE_NAME
  | typeof BENEFITS_PAGE_NAME
  | typeof CHECKS_PAGE_NAME
  | typeof COMPANY_PAGE_NAME
  | typeof COMPANY_SELECTION_PAGE_NAME
  | typeof EMPLOYEES_PAGE_NAME
  | typeof FORGOT_PASSWORD_PAGE_NAME
  | typeof GIFT_CARDS_PAGE_NAME
  | typeof HOME_PAGE_NAME
  | typeof LOGIN_PAGE_NAME
  | typeof LOGIN_SUCCESS_PAGE_NAME
  | typeof LOGOUT_PAGE_NAME
  | typeof LOGOUT_SUCCESS_PAGE_NAME
  | typeof MANAGERS_PAGE_NAME
  | typeof ONBOARDING_PAGE_NAME
  | typeof ORDERS_PAGE_NAME
  | typeof PASSWORD_RESET_PAGE_NAME
  | typeof PERMISSIONS_SETS_PAGE_NAME
  | typeof PROFILE_PAGE_NAME
  | typeof PROFILES_PAGE_NAME
  | typeof PURCHASES_PAGE_NAME
  | typeof REGISTER_PAGE_NAME
  | typeof SETTINGS_PAGE_NAME;

export type Page = {
  path: string;
  permissionsScopes?: PermissionsScope[];
  tourAnchor?: { from: TourStepId; to?: TourStepId };
};

export const PAGES = {
  [ACCOUNTING_PAGE_NAME]: { path: '/accounting', permissionsScopes: ['accounting'] },
  [ADMIN_PAGE_NAME]: { path: '/admin' },
  [BENEFITS_PAGE_NAME]: {
    path: '/benefits',
    permissionsScopes: ['benefits'],
    tourAnchor: { from: 'benefits', to: 'benefits' },
  },
  [CHECKS_PAGE_NAME]: { path: '/smood-checks', tourAnchor: { from: 'checks', to: 'checks' } },
  [COMPANY_PAGE_NAME]: { path: '/company', tourAnchor: { from: 'company', to: 'company_creditCard' } },
  [COMPANY_SELECTION_PAGE_NAME]: { path: '/company-selection', permissionsScopes: ['companySelection'] },
  [EMPLOYEES_PAGE_NAME]: { path: '/employees', tourAnchor: { from: 'employees', to: 'employees' } },
  [FORGOT_PASSWORD_PAGE_NAME]: { path: '/forgot-password' },
  [GIFT_CARDS_PAGE_NAME]: {
    path: '/gift-cards',
    permissionsScopes: ['giftCards'],
    tourAnchor: { from: 'giftCards', to: 'giftCards' },
  },
  [HOME_PAGE_NAME]: { path: '/' },
  [LOGIN_PAGE_NAME]: { path: '/login' },
  [LOGIN_SUCCESS_PAGE_NAME]: { path: '/login/success' },
  [LOGOUT_PAGE_NAME]: { path: '/logout' },
  [LOGOUT_SUCCESS_PAGE_NAME]: { path: '/logout/success' },
  [MANAGERS_PAGE_NAME]: { path: '/managers', tourAnchor: { from: 'managers', to: 'managers' } },
  [ONBOARDING_PAGE_NAME]: { path: '/onboarding' },
  [ORDERS_PAGE_NAME]: { path: '/orders', permissionsScopes: ['orders'], tourAnchor: { from: 'orders', to: 'orders' } },
  [PASSWORD_RESET_PAGE_NAME]: { path: '/auth/reset' },
  [PERMISSIONS_SETS_PAGE_NAME]: { path: '/admin/permissions/sets' },
  [PROFILE_PAGE_NAME]: { path: '/profiles/[id]' },
  [PROFILES_PAGE_NAME]: {
    path: '/profiles',
    permissionsScopes: ['profiles'],
    tourAnchor: { from: 'profiles', to: 'profiles' },
  },
  [PURCHASES_PAGE_NAME]: { path: '/purchases', permissionsScopes: ['purchases'] },
  [REGISTER_PAGE_NAME]: { path: '/register' },
  [SETTINGS_PAGE_NAME]: { path: '/settings' },
} as const satisfies Record<PageName, Page>;
export type Pages = typeof PAGES;

export type PageLink = { icon: ReactNode; page: PageName };

export const PAGE_TABS = {
  admin: [
    { key: 'statements', permissionsScope: 'adminOpenInvoices' },
    { key: 'companies', permissionsScope: 'adminCompanies' },
    { key: 'giftCards', permissionsScope: 'adminGiftCardRequests' },
    { key: 'permissions', permissionsScope: 'adminPermissions' },
    { key: 'payments', permissionsScope: 'adminDatatrans' },
  ],
  company: [
    { key: 'information', permissionsScope: 'companyInformation' },
    { key: 'locations', permissionsScope: 'companyLocation' },
    {
      highlightId: 'company-tabs-costCenters',
      key: 'costCenters',
      permissionsScope: 'companyCostCenter',
    },
    {
      highlightId: 'company-tabs-creditCard',
      key: 'creditCard',
      permissionsScope: 'companyInformation',
    },
  ],
  employees: [
    { key: 'employees', permissionsScope: 'employees' },
    { key: 'invitations', permissionsScope: 'employees' },
  ],
  managers: [
    { key: 'managers', permissionsScope: 'managers' },
    { key: 'invitations', permissionsScope: 'managers' },
  ],
  smoodChecks: [
    { key: 'checks', permissionsScope: 'smoodChecks' },
    { key: 'distributions', permissionsScope: 'smoodChecks' },
  ],
} as const satisfies { [k in PageName]?: TabDefinition[] };
export type PageTabs = typeof PAGE_TABS;
export type TabKey = Value<PageTabs>[number]['key'];

export type TabDefinition = {
  actions?: ReactNode;
  highlightId?: TourHighlightId;
  key: string;
  label?: ReactNode;
  permissionsScope?: PermissionsScope;
};
export type PageTab = { component?: ReactNode } & TabDefinition;

export const TOUR_STEPS = [
  { id: 'splashscreen' },
  { highlightId: 'left-nav-company', id: 'company', page: COMPANY_PAGE_NAME },
  {
    highlightId: 'company-tabs-costCenters',
    id: 'company_costCenters',
    page: COMPANY_PAGE_NAME,
    permissionsScope: 'companyCostCenter',
  },
  {
    highlightId: 'company-tabs-creditCard',
    id: 'company_creditCard',
    page: COMPANY_PAGE_NAME,
    permissionsScope: 'companyCostCenter',
  },
  { highlightId: 'left-nav-profiles', id: 'profiles', page: PROFILES_PAGE_NAME },
  { highlightId: 'left-nav-managers', id: 'managers', page: MANAGERS_PAGE_NAME },
  { highlightId: 'left-nav-employees', id: 'employees', page: EMPLOYEES_PAGE_NAME },
  { highlightId: 'left-nav-benefits', id: 'benefits', page: BENEFITS_PAGE_NAME },
  { highlightId: 'left-nav-smoodChecks', id: 'checks', page: CHECKS_PAGE_NAME },
  { highlightId: 'left-nav-giftCards', id: 'giftCards', page: GIFT_CARDS_PAGE_NAME },
  { highlightId: 'left-nav-orders', id: 'orders', page: ORDERS_PAGE_NAME },
  { highlightId: 'left-nav-help', id: 'close' },
] as const satisfies { id: string; highlightId?: string; page?: PageName; permissionsScope?: PermissionsScope }[];

type TourSteps = typeof TOUR_STEPS;
export type TourStepId = TourSteps[number]['id'];
export type TourHighlightId = ValueProperty<TourSteps, 'highlightId'>;
export type TourStep = { highlightId?: TourHighlightId; id: TourStepId; page?: PageName };
