import * as jwt from 'jsonwebtoken';

export function decodeJwt<T extends Partial<jwt.JwtPayload>>(token: string) {
  const payload = jwt.decode(token, { json: true });

  if (!payload) {
    throw new Error('The jwt could not be decoded');
  }

  return payload as T;
}

export function getJwtExp(token: string) {
  const payload = decodeJwt(token);
  return payload.exp;
}

export function getJwtExpirydate(token: string) {
  const payload = decodeJwt(token);
  return typeof payload?.exp === 'number' ? new Date(payload.exp * 1000) : null;
}

export function isJwtExpired(token: string, refDate?: Date) {
  const exp = getJwtExp(token);
  const timestamp = refDate?.valueOf() ?? Date.now();
  return typeof exp === 'number' && exp <= timestamp / 1000;
}
